<template>
    <div class="item-list flex-column">
        <div class="headertop">
            <div class="header-center flex-layout">
                <div class="center">
                    <ul class="flex-align-between">
                        <li>
                            <div class="item-top">
                                {{ parseInt(userInfodata.learnTime / 60) }}<span>分钟</span>
                            </div>
                            <div class="item-bom">学习总时长</div>
                        </li>
                        <li>
                            <div class="item-top">
                                {{ userInfodata.projectCount }}<span>个</span>
                            </div>
                            <div class="item-bom">参与项目数量</div>
                        </li>
                        <li>
                            <div class="item-img flex-align-center">
                                <img :src="downloadURL + userInfo.avatar" :onerror="$store.state.user.defaultAvatar"
                                    alt />
                            </div>
                            <div class="item-top1">
                                {{ userInfo.name || "默认用户" }}
                                <img src="@/assets/images/home/V@2x.png" alt />
                                <span class="vip">1</span>
                            </div>
                            <!-- <div class="item-bom"> -->
                            <!-- {{ userInfo.schoolName ||'' }}<span>{{ roletype[role] }}</span> -->
                            <!-- </div> -->
                        </li>
                        <li>
                            <div class="item-top">
                                {{ userInfodata.questCount }}<span>个</span>
                            </div>
                            <div class="item-bom">提交作业数量</div>
                        </li>
                        <li>
                            <div class="item-top">
                                {{ userInfodata.awardCount }}<span>个</span>
                            </div>
                            <div class="item-bom">获得证书</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="year-list">
                <ul class="flex-align">
                    <li>年份</li>
                    <li class="csp" :class="{ active: filterYears === index }" v-for="(item, index) in YearsList"
                        :key="index" @click="changeyears(item, index)">{{ item }}
                        <div v-if="filterYears === index" class="divvs"></div>

                    </li>
                </ul>
            </div>
        </div>
        <div class="container ">
            <div class="content-item">
                <ul>
                    <li class="flex-align-between csp" v-for="(item, index) in ProjectsList" :key="index"
                        @click="gotoproject(item)">
                        <div class="content-item-img">
                            <div class="years">
                                {{ dayjs(item.periodStartTime).format("YYYY") }}年
                            </div>
                            <img :src="downloadURL + item.cover" :onerror="defaultCourseCover[index % 3]" alt="" />
                        </div>
                        <div class="content-item-center">
                            <div class="title">{{ item.name }}</div>
                            <div class="content wes-2">{{ item.introduction }}</div>
                            <div class="time">
                                {{ dayjs(item.periodStartTime).format("YYYY-MM-DD") }} -
                                {{ dayjs(item.periodFinishTime).format("YYYY-MM-DD") }}
                            </div>
                        </div>
                        <div class="content-item-title flex-align-center"
                            :class="{ 'item-title-active': item.timetype === 2 }">
                            {{ TimeTypes[item.timetype] }}
                        </div>
                    </li>
                </ul>
            </div>
            <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                :limit.sync="pages.pageSize" :pageSizes="[3, 6, 9]" @pagination="handlePageChange">
            </pagination>
            <Empty :show="pages.total == 0"></Empty>
        </div>
        <!-- 填写身份证弹窗 -->
        <el-dialog title="请完善个人用户信息" :visible.sync="dialogVisible" width="30%" custom-class="myDialog">
            <div class="idCode flex-align">
                身份证号:
                <el-input v-model="input" style="width:80%" placeholder="请输入身份证号码"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="idCodeChange">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <Footer></Footer> -->
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: "item-list",
        data() {
            return {
                defaultCourseCover: [
                    //默认课程cover图片 列表页
                    `this.src="${require("@/assets/images/home/item-list1.png")}"`,
                    `this.src="${require("@/assets/images/home/item-list2.png")}"`,
                    `this.src="${require("@/assets/images/home/item-list3.png")}"`,
                    // `this.src="${require("@/assets/images/course/course-cover04.png")}"`,
                    // `this.src="${require("@/assets/images/course/course-cover05.png")}"`,
                    // `this.src="${require("@/assets/images/course/course-cover06.png")}"`,
                ],
                TimeTypes: {
                    1: "未开始",
                    2: "进行中",
                    3: "已结束",
                },
                // userInfo: JSON.parse(sessionStorage.getItem("userinfo")),
                userInfo: {},
                // role: sessionStorage.getItem("role"),
                roletype: {
                    parent: "家长", //家长
                    normal: "师范生", //师范生
                    teacher: "老师", //老师
                    student: "学生", //学生
                    expert: "专家", //专家
                    assistant: "助教", //助教
                    school: "学校", //学校
                    leader: "领导", //领导
                },
                ProjectsList: [],
                filterYears: 0,
                Years: null,
                YearsList: [],
                userInfodata: {},
                pages: {
                    //分页
                    currentPage: 1,
                    pageSize: 6,
                    total: 0,
                },
                dialogVisible: false,
                input: ''
            };
        },
        computed: {},
        components: {},
        methods: {
            //跳转项目页
            gotoproject(item) {
                console.log(item)
                if(item.thirdIds){
                    this.getGoLxUrl(item.thirdIds)
                    return
                }
                axios.defaults.headers['projectId'] = item.id
                this.$store.commit('getCurrentProject', item);
                // sessionStorage.setItem('projectID', item.id);
                // // 判断是否是003项目
                if (item.id == 144) {
                    // 判断是否填写身份证信息
                    if (this.userInfo.idCard && this.userInfo.idCard.trim()) {
                        console.log('有身份证');
                        this.getNoticeList(item.id).then((res) => {
                            console.log(res);
                            this.$store.commit('getHasMain', res);
                            if (res) {
                                this.$router.push(`/main/${item.id}`)
                            }
                            if (!res) {
                                let role = this.$store.state.user.role;
                                let pathMap = {
                                    'parent': "/home/schedule", //家长
                                    'normal': "/home/schedule", //师范生
                                    'teacher': "/home/schedule", //老师
                                    'student': "/home/schedule", //学生
                                    'expert': "/home/schedule", //专家
                                    'assistant': "/home/schedule", //助教
                                    'school': "/home/leader_home", //学校
                                    'leader': "/home/leader_home", //领导（管理者）
                                    'manager': "/home/weekly", //管理员
                                }
                                this.$router.push(pathMap[role]);
                            }
                        });
                    } else {
                        console.log('无身份证，弹窗');
                        this.dialogVisible = true;
                        return
                    }
                }  else {
                    this.getNoticeList(item.id).then((res) => {
                        console.log(res);
                        this.$store.commit('getHasMain', res);
                        if (res) {
                            this.$router.push(`/main/${item.id}`)
                        }
                        if (!res) {
                            let role = this.$store.state.user.role;
                            let pathMap = {
                                'parent': "/home/schedule", //家长
                                'normal': "/home/schedule", //师范生
                                'teacher': item.id == 735?"/home/mytask":"/home/schedule", //老师
                                'student': "/home/schedule", //学生
                                'expert': "/home/schedule", //专家
                                'assistant': "/home/schedule", //助教
                                'school': "/home/leader_home", //学校
                                'leader': "/home/leader_home", //领导（管理者）
                                'manager': "/home/weekly", //管理员
                            }
                            this.$router.push(pathMap[role]);
                        }
                    });
                }

            },
            // 获取通知公告
            async getNoticeList(id) {
                let params = {
                    projectId: id,
                    page: 1,
                    limit: 4,
                }
                let resData = await this.$Api.Home.getNewsListByProjectId(params);
                // console.log(resData);
                let hasMainPage = resData.data.list.length > 0
                return new Promise((resolve, reject) => {
                    resolve(hasMainPage)
                })
            },
            //改变年限
            changeyears(item, index) {
                this.Years = item;
                this.filterYears = index;
                this.pages.currentPage = 1;
                // this.pages.pageSize = 6;
                this.userProjects();
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex;
                this.pages.pageSize = data.pageSize;
                this.userProjects();
            },
            //获取查询筛选条件
            async userProjectYears() {
                let params = {};
                let resData = await this.$Api.Home.userProjectYears(params);
                this.YearsList = resData.data.reverse();
                this.YearsList.unshift("全部");
            },
            //用户项目信息
            async getuserInfo() {
                let params = {
                    id: 1,
                };
                let resData = await this.$Api.Home.getuserInfo(params);
                this.userInfodata = resData.data;
            },

            //用户项目列表
            async userProjects() {
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                };
                if (this.Years !== "全部") {
                    params.year = this.Years;
                }
                let resData = await this.$Api.Home.userProjects(params);
                // console.log(resData.data);
                this.ProjectsList = resData.data.records;
                this.ProjectsList.map((item) => {
                    let activityBT = this.dayjs(item.periodStartTime).unix(); //开始时间
                    let activityET = this.dayjs(item.periodFinishTime).unix(); //结束时间
                    let currentTM = this.dayjs(new Date()).unix(); //当前时间
                    if (currentTM < activityBT && currentTM < activityET) {
                        item.timetype = 1; //未开始
                    }
                    if (currentTM >= activityBT && currentTM <= activityET) {
                        item.timetype = 2; //进行中
                    }
                    if (currentTM > activityBT && currentTM > activityET) {
                        item.timetype = 3; //已结束
                    }
                });
                this.pages.total = resData.data.total;
            },
            // 校验身份证
            idCodeChange() {
                console.log(this.input);
                let reg =
                    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
                if (!reg.test(this.input)) {
                    this.$message.error('请输入正确的身份证号码')
                } else {
                    console.log('校验身份证通过');
                    let data = {
                        id: this.userInfo.id,
                        idCard: this.input
                    }
                    this.$Api.My.getfixinfo(data)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.userinfo();
                                this.dialogVisible = false;
                                this.getNoticeList(144).then((res) => {
                                    console.log(res);
                                    this.$store.commit('getHasMain', res);
                                    if (res) {
                                        this.$router.push(`/main/144`)
                                    }
                                    if (!res) {
                                        let role = this.$store.state.user.role;
                                        let pathMap = {
                                            'parent': "/home/schedule", //家长
                                            'normal': "/home/schedule", //师范生
                                            'teacher': "/home/schedule", //老师
                                            'student': "/home/schedule", //学生
                                            'expert': "/home/schedule", //专家
                                            'assistant': "/home/schedule", //助教
                                            'school': "/home/leader_home", //学校
                                            'leader': "/home/leader_home", //领导（管理者）
                                            'manager': "/home/weekly", //管理员
                                        }
                                        this.$router.push(pathMap[role]);
                                    }
                                });
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        })
                        .catch(err => {
                            console.log('校验失败', err);
                        })


                }
            },
            async userinfo() {
                let resData = await this.$Api.Home.userInfo();
                console.log(resData)
                this.$store.commit('getUserInfo', resData.data);
                this.form = resData.data
            },
            // 获取乐享跳转地址
            async getGoLxUrl(thirdIds){
                let data = {
                    thirdIds
                }
                let resData = await this.$Api.Home.getGoLxUrl(data);
                console.log('获取乐享链接:',resData);
                window.open(resData.data);
            }
        },
        mounted() {
            this.$store.commit('getCurrentProject', {});
            this.userInfo = this.$store.state.user.userInfo
            this.userProjectYears();
            this.userProjects();
            this.getuserInfo();



            // TEST
            let arr = ['这是第一行', '第二行'];
            arr.map(item => {
                console.log(item.length)
            })
        },
        activated() {},
    };
</script>

<style lang="less" scoped>
    .item-list {
        min-height: 100vh;
        // max-width: 1920px;
        min-width: 1000px;
        // transform: scale(1);
        // 黑白模式
        // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        // -webkit-filter: grayscale(100%);
    }

    /deep/ .header {
        // background-color: transparent;
    }

    .headertop {
        margin-top: -91px;
        height: 360px;
        // overflow: hidden;
        background-image: url("../../assets/images/home/header-top.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 91px;
        box-sizing: border-box;
    }

    .header-center {
        .center {
            min-width: 900px;
            margin: 0 auto;
            width: 900px;
            padding: 15px 0 40px 0;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;

            .item-img {
                // width: 106px;
                height: 106px;
                // background: #3370ff;
                border-radius: 50%;
                margin: 0 auto;

                img {
                    width: 106px;
                    height: 106px;
                    border-radius: 50%;
                }
            }

            .item-top {
                text-align: center;
                font-size: 28px;

                span {
                    font-size: 18px;
                }
            }

            .item-top1 {
                text-align: center;
                font-size: 18px;

                .vip {
                    color: #fcd012;
                }
            }

            .item-bom {
                text-align: center;
                font-size: 13px;

                span {
                    margin-left: 10px;
                }
            }
        }
    }

    .year-list {
        background-color: rgba(113, 157, 238, 0.5);
        height: 80px;

        ul {
            min-width: 900px;
            width: 900px;
            margin: 0 auto;

            li {
                position: relative;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 26px;
                padding: 5px 30px;
                // cursor: pointer;
                border-radius: 30px;
                margin: 20px 10px;

                &.active {
                    border: 1px solid #ffffff;
                }

            }

            .divvs {
                content: "";
                z-index: 9999;
                width: 0px;
                height: 0px;
                border-top: 10px solid #196bf8;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .container {
        flex: 1;
        align-items: flex-start;
        min-width: 900px;
        width: 900px;
        margin: 0 auto;
        padding: 68px 0;

        .content-item {
            ul {
                li {
                    background: #ffffff;
                    box-shadow: 0px 0px 13px 0px rgba(127, 127, 127, 0.14);
                    border-radius: 10px;
                    margin-bottom: 60px;

                    .content-item-img {
                        // flex: 1;
                        width: 380px;
                        height: 219px;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        .years {
                            position: absolute;
                            bottom: 26px;
                            left: 23px;
                            font-size: 18px;
                            line-height: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            border: 1px solid #ffffff;
                            border-radius: 25px;
                            padding: 5px 30px;
                        }
                    }

                    .content-item-center {
                        flex: 1;
                        padding: 55px 40px;

                        .title {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }

                        .content {
                            padding-top: 10px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }

                        .time {
                            padding-top: 10px;
                            font-size: 15px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #a2a2a3;
                        }
                    }

                    .content-item-title {
                        width: 150px;
                        color: #dddddd;
                    }

                    .item-title-active {
                        color: #3370ff;
                    }
                }
            }
        }
    }

    .myDialog {
        .idCode {
            justify-content: space-around;
        }
    }

    @media screen and (min-width: 1250px) {
        .headertop {
            height: 490px;
        }

        .header-center {
            .center {
                min-width: 1024px;
                margin: 0 auto;
                width: 1024px;
                padding: 60px 0 95px 0;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;

                .item-img {
                    // width: 136px;
                    height: 136px;
                    border-radius: 50%;


                    img {
                        width: 136px;
                        height: 136px;
                        border-radius: 50%;
                    }
                }

            }
        }

        .year-list {
            ul {
                min-width: 1200px;
                width: 1200px;
                margin: 0 auto;

                li {
                    line-height: 26px;
                    padding: 5px 40px;
                    margin: 20px 20px;

                }

                .divvs {
                    content: "";
                    z-index: 9999;
                    width: 0px;
                    height: 0px;
                    border-top: 10px solid #196bf8;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    position: absolute;
                    top: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .container {
            flex: 1;
            align-items: flex-start;
            min-width: 1200px;
            width: 1200px;
            margin: 0 auto;
            padding: 68px 0;

            .content-item {
                ul {
                    li {
                        .content-item-img {
                            width: 414px;
                            height: 229px;
                            position: relative;

                            img {
                                width: 414px;
                                height: 229px;
                            }
                        }
                    }
                }
            }
        }

        .myDialog {
            .idCode {
                justify-content: space-around;
            }
        }
    }
</style>